.login {
  background-color: var(--color-white);
  width: 400px;
  margin: auto;
  margin-top: 100px;
  box-shadow: 1px 1px var(--color-shadow-grey);
  border: solid 1px var(--color-light);
  border-radius: 5px;
}

.header {
  background-color: var(--color-dark-blue);
  color: var(--color-white);
  padding: 20px 40px;
}

.body {
  padding: 40px;
}

.center {
  margin-top: 30px;
  text-align: center;
}
