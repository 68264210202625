.sidenav {
  .topPanel {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
    height: 60px;
    padding-top: 15px;

    .logo {
      text-align: center;
      font-size: 1.2em;

      a {
        color: var(--color-light);
        text-decoration: none;
      }
    }

    .toggle {
      height: 60px;
      width: 50px;
      font-size: 1.2em;
      position: absolute;
      left: 12px;
      top: 15px;
      cursor: pointer;
    }
  }

  .panel {
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    background-color: var(--color-dark);
    overflow-x: hidden;
    padding-top: 5px;

    & .items {
      max-height: 0;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      &.expanded {
        overflow: hidden;
        padding-bottom: 30px;
        max-height: 500px; /* approximate max height */
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }

      & .link {
        padding: 8px 8px 8px 34px;
        text-decoration: none;
        font-size: 1.9rem;
        color: var(--color-white);
        display: block;
        transition: 0.3s;
        cursor: pointer;

        &.active {
          color: var(--color-blue);
        }

        & .icon {
          width: 20px;
          margin-right: 14px;
          color: var(--color-grey);
        }

        & .rightIcon {
          display: none;
        }
      }

      & .subItems {
        display: none;

        &.expanded {
          display: block;
        }

        & .link {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .sidenav {
    .topPanel {
      top: 0;
      position: fixed;
      width: var(--sidebar-width);
      height: var(--header-height);
      padding-top: 10px;
  
      .logo {
        text-align: center;
        font-size: 1.2em;
      }
  
      .toggle {
        display: none;
      }
    }

    .panel {
      top: var(--header-height);
      height: 100%;
      width: var(--sidebar-width);
      position: fixed;

      & .items {
        padding-top: 30px;
        max-height: 100%;

        .link {
          margin-bottom: 6px;

          & .rightIcon {
            display: block;
            float: right;
            margin-top: 4px;
            margin-right: 14px;
            color: var(--color-grey);
          }
        }
      }

      & a:hover {
        color: var(--color-blue);
      }

      & .toggle {
        display: none;
      }
    }
  }
}
