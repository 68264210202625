.container {
  padding: 5px 10px;
  display: inline-block;
  min-width: 100px;

  & .title {
    text-align: center;
  }

  & .items {
    display: none;
    position: absolute;
    background-color: var(--color-dark);
    border: solid 1px var(--color-light-grey);
    box-shadow: 1px 1px var(--color-shadow-grey);
    z-index: 10px;
    border-radius: 8px;

    &.expanded {
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: var(--color-light);
  }
}
