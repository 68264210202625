.container {
  margin: var(--gutter);
  padding: 5px 5px;
}

@media screen and (min-width: 900px) {
  .container {
    margin: var(--gutter);
    padding: 10px 40px 60px 40px;
  }
  
  .auth {
    margin-top: var(--header-height);
    margin-left: var(--sidebar-width);
    padding-top: 20px;
  } 
}