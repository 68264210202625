:root {
  --mobile-breakpoint: 450px;

  --color-dark: #1b2430;
  --color-light: #f1f1f1;

  --color-white: #fff;
  --color-black: #000;
  --color-light-grey: #C8C8C8;
  --color-grey: #818181;
  --color-blue: #2da0f9;
  --color-purple: #9b27f5;
  --color-green: #50d168;
  --color-orange: #ff9800;
  --color-yellow: #f5d327;
  --color-red: red;
  --color-dark-blue: #212c3a;

  --color-shadow-grey: #E0E0E0;
  --color-link-blue: #5fb4fa;

  --color-overlay: rgba(0, 0, 0, 0.50);

  --border-size: 1px;

  --sidebar-width: 240px;
  --header-height: 60px;
  --gutter: 2px;
}
