.header {
  background-color: var(--color-white);
  height: var(--header-height);
  box-shadow: 0 1px var(--color-shadow-grey);
  display: flex;
}

.actions {
  width: 100%;
  text-align: right;
  padding-top: 10px;
  padding-right: 16px;

  .icon {
    font-size: 1.7em;
  }
}

@media screen and (min-width: 900px) {
  .header {
    margin-left: var(--sidebar-width);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .actions {
    position: fixed;
    right: 0;
    padding-right: 30px;
  }  
}
