.text-center {
  text-align: center;
}

.shadow {
  box-shadow: 1px 1px var(--color-shadow-grey);
}

.bg-purple {
  background-color: var(--color-purple);
}

.bg-blue {
  background-color: var(--color-blue);
}

.bg-green {
  background-color: var(--color-green);
}

.bg-orange {
  background-color: var(--color-orange);
}

.bg-yellow {
  background-color: var(--color-yellow);
}

.bg-red {
  background-color: var(--color-red);
}

.color-red {
  color: var(--color-red);
}
